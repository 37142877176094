/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
			$('.navbar-toggle').click(function() {
				if(!$('.navbar-collapse').hasClass('collapsing')){
					if($('header').hasClass('open')){
						$('header').removeClass('open');
						$('header').addClass('closed');
						$('.navbar-header button').addClass('collapsed');
						$('.navbtntxt').html('Menu');
						$('#navOff').hide();
					}else{
						$('header').removeClass('closed');
						$('header').addClass('open');
						$('.navbar-header button').removeClass('collapsed');
						$('.navbtntxt').html('Close');
						$('#navOff').show();
					}
				}
			});

			$('#navOff').click(function() {
				$(".navbar-toggle").click();
			});

			$(window).on("scroll", function () {
				if ($(this).scrollTop() > 100) {
					$("#header").addClass("hdrshrink");
					$("#header").removeClass("hdrunshrink");
				}
				else {
					$("#header").removeClass("hdrshrink");
					$("#header").addClass("hdrunshrink");
				}
			});

			equalheight = function(container){

				var currentTallest = 0,
     				currentRowStart = 0,
     				rowDivs = [],
     				$el,
     				topPosition = 0;
 				$(container).each(function() {

   				$el = $(this);
   				$($el).height('auto');
   				topPostion = $el.position().top;

   				if (currentRowStart !== topPostion) {
     				for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       				rowDivs[currentDiv].height(currentTallest);
     				}
     				rowDivs.length = 0; // empty the array
     				currentRowStart = topPostion;
     				currentTallest = $el.height();
     				rowDivs.push($el);
   				} else {
     				rowDivs.push($el);
     				currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  				}
   				for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     				rowDivs[currentDiv].height(currentTallest);
   				}
 				});
			};


			$(window).load(function() {
  				equalheight('.eqheight');
			});

			$(window).resize(function(){
  				equalheight('.eqheight');
			});

            $('body').on('input', "#myInput", function() {
                  if($(this).val().length) {
                    var hasVal = true;
                  } else {
                    var hasVal = false;
                  }
                  console.log('Input has value (true / false): ', hasVal + " (" + $(this).val() + ")");
                });


            $('#gform_submit_button_2').on('click',function(e) {
                var $value = $('#input_2_7');
                if($($value).val().length) {
                  e.preventDefault();
                }
            });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

   			$(window).load(function() {

   				var grid = jQuery('#sorting-grid');

				grid.isotope({
  					itemSelector : '.sorting-item',
  					layoutMode: 'fitRows'
				});

				$( window ).resize(function() {
  					grid.isotope({
	  					itemSelector : '.sorting-item',
	  					layoutMode: 'fitRows'
					});
				});

			    // bind filter button click
			    $('#filters').on( 'click', 'button', function() {
			    var filterValue = $( this ).attr('data-filter');
			    grid.isotope({ filter: filterValue });
			    });

			    // change is-checked class on buttons
			    $('.button-group').each( function( i, buttonGroup ) {
			      var $buttonGroup = $( buttonGroup );
			      $buttonGroup.on( 'click', 'button', function() {
			      $buttonGroup.find('.is-checked').removeClass('is-checked');
			      $( this ).addClass('is-checked');
			      });
			    });

			    $(function() {
					$('.branding-name').matchHeight({
				        target: $('.brand-logo-img')
				    });
				});

			});
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Resources
    'resources': {
      init: function() {


      	 if(window.location.href.indexOf("resources") > -1) {
       		$("#nav-resources").css("background-color", "#ecac00");
    	}

      	$(window).load(function() {

   				var grid = jQuery('#sorting-grid');

				grid.isotope({
  					itemSelector : '.sorting-item',
  					layoutMode: 'fitRows'
				});

				$( window ).resize(function() {
  					grid.isotope({
	  					itemSelector : '.sorting-item',
	  					layoutMode: 'fitRows'
					});
				});

			    // bind filter button click
			    $('#filters').on( 'click', 'button', function() {
			    var filterValue = $( this ).attr('data-filter');
			    grid.isotope({ filter: filterValue });
			    });

			    // change is-checked class on buttons
			    $('.button-group').each( function( i, buttonGroup ) {
			      var $buttonGroup = $( buttonGroup );
			      $buttonGroup.on( 'click', 'button', function() {
			      $buttonGroup.find('.is-checked').removeClass('is-checked');
			      $( this ).addClass('is-checked');
			      });
			    });
			});
      }
    },

    // Our Clients page, note the change from our-clients to our_clients.
    'our_clients': {
      init: function() {

			$(window).load(function() {

  				var things = jQuery('#clients');
				var filters = {};

				things.isotope({
  					itemSelector : '.client',
  					resizable: false, // disable normal resizing
  					// set columnWidth to a percentage of container width
  					masonry: { columnWidth: jQuery('.clientImage').width(), gutter: 30 }
				});

				$( window ).resize(function() {
  					things.isotope({
	  					itemSelector : '.client',
	  					resizable: false, // disable normal resizing
	  					// set columnWidth to a percentage of container width
	  					masonry: { columnWidth: jQuery('.clientImage').width(), gutter: 30 }
					});
				});

		  	// when everything loads, make the "all" options selected
		  	jQuery('.filters a[data-filter-value=""]').addClass('selected');

		  	// filter buttons
		  	jQuery('.filters a.filteroption').click(
					function(e){
						e.preventDefault();

						$('#mynoresults').hide();
						var clicked_filter = jQuery(this);

						// if the clicked link is already selected, don't do anything
						if ( clicked_filter.hasClass('selected') ) {
					   	return;
						}
						var optionSet = clicked_filter.parents('.option-set');

						// get rid of the ".selected" class on any links in this group and put it on the clicked link
						optionSet.find('.selected').removeClass('selected');
						clicked_filter.addClass('selected');

						// store the filters in the filters object, indexed by the group they're in
						var group = optionSet.attr('data-filter-group');
						filters[ group ] = clicked_filter.attr('data-filter-value');

						// convert the filters object into an array of strings which are CSS class selectors
						var filters_to_use = [];
						for ( group in filters ) {
				   		filters_to_use.push( filters[ group ] );
						}

						// smash the array together to get a big selector which will filter all elements with the filter classes
						var selector = filters_to_use.join('');

						things.isotope( 'on', 'layoutComplete',
							function( isoInstance, laidOutItems ) {
	 							if ( laidOutItems.length === 0 ) {
		 							$('#mynoresults').show();
	 							} else {
		 							$('#mynoresults').hide();
	 							}
							}
						);

						// run the filter on the isotope element
						things.isotope({
				   		filter: selector,
				   		resizable: false, // disable normal resizing
				   		// set columnWidth to a percentage of container width
				   		masonry: { columnWidth: jQuery('.clientImage').width(), gutter: 30 },
							onLayout: function() {

							}
						});
					}
				);
			});
      }
    },
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
